.slideshows {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 60%; */
  }
  
  .card {
    margin: 10px 10px 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    height: 350px;
    /* flex-basis: calc(33.33% - 20px) */
    
  }

  .card2 {
    margin: 10px 10px 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    height: 350px;
    /* flex-basis: calc(33.33% - 20px) */
  }
  .bu_n{
    background-color: #16887d;
  }
  
  .controls {
    margin-top: 30%;
    right: 45%;
    position: absolute;
  }

  .med_img1{
    height: 100%;
    width: 100%;
    position: relative;
  }

  .med_img{
    width: 100%;
    height: 70%;
  }
  
  .controls button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .car_d{
    display: flex;
    margin-left: 133px;
    width: 90%;

  }

  @media screen and (min-width:320px) and (max-width:480px)  {
    .car_d {
      display: flex;
      margin-left: 23px;
      width: 90%;
      }

      .controls {
        margin-top: 5%; 
        position: relative;
        right: 0%;
    }

    .card-container, .slideshows {
      align-items: center;
      display: block;
      flex-direction: row;
      scroll-behavior: initial;
  }
  
    }

  @media screen and (min-width:992px) and (max-width:1020px)  {

  .controls {
    margin-top: 44%;
    position: absolute;
    right: 45%;
}

  }

  @media screen and (min-width:1024px) and (max-width:1199px)  {
  .controls {
    margin-top: 42%;
    position: absolute;
    right: 45%;
   }

  }
  
  @media screen and (min-width:768px) and (max-width:990px)  {
  .card, .card2 {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 350px;
    margin: 10px;
    padding: 10px;
}

.controls {
  margin-top: 56%;
  position: absolute;
  right: 45%;
}
  }

  @media screen and (min-width:1200px) and (max-width:1250px)  {
    .controls {
      margin-top: 35%;
      position: absolute;
      right: 45%;
     }
  
    }

    @media screen and (min-width:1251px) and (max-width:1300px)  {
      .controls {
        margin-top: 35%;
        position: absolute;
        right: 45%;
       }
    
      }