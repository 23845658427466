  
 .box{
 
  /* background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%; */
 }

 .error{
   color: red;
 }
  .welcome-to-market {
  color: #7b7b7b;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 18px;
  font-weight: 400;
  /* height: 24px; */
  letter-spacing: -0.09px;
  line-height: normal;
  position: absolute;
  top: 15px;
 
  }


   .text-wrapper {
    color: #16887d;
    font-family: "Segoe UI-Regular", Helvetica;
    font-size: 18px;
    position: relative;
    text-align: right;
    top: 15px;
    cursor: pointer;
    }

     .header {
      background-color: transparent;
      position: relative;
      width: 96%;
      }
      .header1 {
        background-color: transparent;
        position: relative;
        width: 99%;
        }
   .log_fun{
    width: 100%
   }
      .row_head{
        background-color: aliceblue;
      }

      .logo_img{
        margin-top: 3%;
      }
      .logo_img1{
        text-align: left;
        margin-top: 3%;
        /* margin-left:5% */
      }

      .floatingAlerts1{
        color: red;
      }

       .footer {
        background-color: transparent;
        position: relative;
        width: 99%;
        padding: 50px;
      }
        .footer1 {
          background-color: transparent;
          position: relative;
          width: 99%;
          padding: 50px;
        }

       .support-center {
    color: #818181;
    font-family: "Segoe UI-Regular", Helvetica;
    font-size: 20px;
    letter-spacing: -0.2px;
    line-height: 40px;
    position: relative;
    text-align: left;
    margin-left: 10px;
    }

       .about-us-team-member {
      color: #818181;
      font-family: "Segoe UI-Regular", Helvetica;
      font-size: 20px;
      letter-spacing: -0.2px;
      line-height: 40px;
      position: relative;
      white-space: nowrap;
      text-align: left
      }
       .sell-on-market-place {
        color: #818181;
        font-family: "Segoe UI-Regular", Helvetica;
        font-size: 20px;
        letter-spacing: -0.2px;
        line-height: 40px;
        position: relative;
        text-align: left
      }

        .quicklink{
        color: #818181;
        font-family: "Segoe UI-Semibold", Helvetica;
        font-size: 24px;
        letter-spacing: -0.24px;
        line-height: normal;
        position: relative;
        text-align: left;
        margin-left: 10px;
      }

         .newsletter{
        color: #818181;
        font-family: "Segoe UI-Semibold", Helvetica;
        font-size: 24px;
        letter-spacing: -0.24px;
        line-height: normal;
        position: relative;
        text-align: left
      }

         .markplace{
        color: #818181;
        font-family: "Segoe UI-Semibold", Helvetica;
        font-size: 24px;
        letter-spacing: -0.24px;
        line-height: normal;
        position: relative;
        text-align: left
      }

        .company{
        color: #818181;
        font-family: "Segoe UI-Semibold", Helvetica;
        font-size: 24px;
        letter-spacing: -0.24px;
        line-height: normal;
        position: relative;
        text-align: left
      }

        .registernow{
        color: #818181;
        font-family: "Segoe UI-Regular", Helvetica;
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: normal;
        position: relative;
        text-align: left
        }

          .email_here{         
            position: relative;
            font-family: "Segoe UI-Regular", Helvetica;
            border-radius: 3px;
            text-align: left;
           
        }
        .email_check{
          display: flex;
        }

           .subscribe{
            color: #fffdfd;
            font-family: "Segoe UI-Bold", Helvetica;
            letter-spacing: -0.11px;
            line-height: normal;
            position: relative;
            background-color: #16887d;
        }

          .followus{
            
            color: #818181;
            font-family: "Segoe UI-Regular", Helvetica;
            font-size: 20px;
            
            letter-spacing: -0.2px;
            line-height: normal;
            position: relative;
            text-align: left;
        }
          .twitter{
          height: 20px;
          position: relative;
          width: 21px;
          height: 16px;
         
          position: relative;
         
          width: 21px;
      }
       .facebook-logo{
        height: 20px;
        position: relative;
        width: 21px;
        height: 16px;
        
        position: relative;
        
        width: 21px;
      }

        .linkedin{
        height: 20px;
        position: relative;
        width: 21px;
        height: 16px;
        
        position: relative;
        
        width: 21px;
      }

        .youtube{
        height: 20px;
        position: relative;
        width: 21px;
        height: 16px;
        
        position: relative;
        
        width: 21px;
      }

      .social_ions{
        text-align: left;
      }
        .copyright{
       
        color: #818181;
        font-family: "Segoe UI-Regular", Helvetica;
        font-size: 18px;
        letter-spacing: -0.18px;
        line-height: normal;
        position: relative;
        text-align: left;
        margin-left: 10px;
        
      }

        .payment-text{
          height: 27px;
          /* width: 223px; */
          color: #818181;
          font-family: "Segoe UI-Regular", Helvetica;
          font-size: 18px;
          height: 27px;
          
          letter-spacing: -0.18px;
          line-height: normal;
          position: relative;
      }

        .payment_img{
         
          object-fit: cover;
          position: relative;
      }

     
         
          .login_now{
            color: #000000;
            font-family: "Segoe UI-Bold", Helvetica;
            font-size: 36px;
            font-weight: 700;
            letter-spacing: 0;
            position: relative;
        }

           .welcome_ba{
            color: #000000;
            font-family: "Segoe UI-Regular", Helvetica;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: normal;
            position: relative;
            
        }

        

         .google{
          background-color: #c2f0ec;
          border-radius: 5px;
          height: 47px;
          position: relative;
          width: 445px;
          border:  #c2f0ec;
        }

        .login_email{
         
          height: 47px;
          position: relative;
          width: 445px;
        }
         .label_email{
          color: #000000;
          font-family: "Segoe UI-Semibold", Helvetica;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          position: relative;

        }

          .input-BG{
            background-color: #c2f0ec;
            border-radius: 5px;
            height: 47px;
            position: relative;
            width: 445px;
            border:  #c2f0ec;
        }
          .label_password{
          color: #000000;
          font-family: "Segoe UI-Semibold", Helvetica;
          font-size: 18px;
          font-weight: 400;          
          letter-spacing: 0;
          position: relative;

        }
          .input-BG1{
          background-color: #c2f0ec;
          border-radius: 5px;
          height: 47px;
          position: relative;
          width: 445px;
          border:  #c2f0ec;
      }
       .check_one{
        height: 47px;
          
          position: relative;
          width: 445px;
          display: flex;
      }
      .for_pass{
          color: #16887d;
          font-family: "Segoe UI-Semibold", Helvetica;
          font-size: 15px;
          font-weight: 400;
          position: relative;
          margin-left: 46%;
    margin-top: 2px;
      }
       .continue{
          background-color: #16887d;
          color: #fff;
          border-radius: 5px;
          height: 47px;
          position: relative;
          width: 445px;
      }
        .not_r{
        height: 47px;
        position: relative;
        /* text-align: center; */
      }
      .signup_c{
         color: #16887d;
         cursor: pointer;
      }

      .store_loc{
      color: #7b7b7b;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 78%;
  letter-spacing: -0.09px;
  line-height: normal;
  position: absolute;
  top: 15px;
      }

      .trackorder{
        color: #7b7b7b;
    font-family: "Segoe UI-Semibold", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 87%;
    letter-spacing: -0.09px;
    line-height: normal;
    position: absolute;
    top: 15px;
    cursor: pointer;
        }

        .country{
          color: #7b7b7b;
      font-family: "Segoe UI-Semibold", Helvetica;
      font-size: 15px;
      font-weight: 400;
      left: 94%;
      letter-spacing: -0.09px;
      line-height: normal;
      position: absolute;
      top: 15px;
          }

          .location{
            color: #7b7b7b;
        font-family: "Segoe UI-Semibold", Helvetica;
        font-size: 15px;
        font-weight: 400;
        left: 99%;
        letter-spacing: -0.09px;
        line-height: normal;
        position: absolute;
        top: 15px;
            }

            .select_product{
              display: flex;
              position: relative;
              margin-left: 45%;
              /* margin-top: -60px; */
          }
            

            .pro_logo1{
              margin-right: 80%;
              margin-top: 2%;
                position: relative;
                cursor: pointer;
               
            }
            .auto_search{
              position: absolute;
              top: 55px;
              left:40%;
            }
            .auto_all{
              position: absolute;
              top: 55px;
              left:35.3%;
              
            }

            .sell_best_product{
            position: relative;
            background-color: #F4F5F5;
            height: auto;
            width: auto;
            }
            
            .select_pro{
              height: 42px;
              width: 66px;
              border: 1px solid #ccc;
            }
           

            .heart{
              position: absolute;
              top: 70px;
              left:85.4%;
              cursor: pointer;
             
            }
           .vector{
            position: absolute;
              top: 70px;
              left:90.4%;
              cursor: pointer;
           }
           .vector1{
            position: absolute;
              top: 70px;
              left:95.4%;
              cursor: pointer;
           }

           .log_reg{
            color: #7b7b7b;
        font-family: "Segoe UI-Semibold", Helvetica;
        font-size: 15px;
        font-weight: 400;
        left: 99%;
        letter-spacing: -0.09px;
        line-height: normal;
        position: absolute;
        top: 55px;
            }

            .mar_place{
              color: #000000;
              font-family: "Segoe UI-Semibold", Helvetica;
              font-size: 36px;
              font-weight: 400;
              height: 48px;
              left: 0;
              letter-spacing: -0.36px;
              line-height: normal;
              position: relative;
              /* top: 50px; */
            }

            .bus_to{
              color: #7b7b7b;
              font-family: "Segoe UI-Regular", Helvetica;
              font-size: 18px;
              font-weight: 400;
              height: 58px;
              left: 0;
              letter-spacing: -0.11px;
              line-height: normal;
              position: relative;
              /* top: 60px; */
              margin-right: 170px;
              margin-left: 160px;
            }

            .slide_off{
              height: 360px;
              left: 0;
              position: relative;
              top: 0;
              width: 830px;
              margin-right: 465px;
            }

            .slide_off1{
              height: 365px;
              left: 64%;
              position: absolute;
              width: 440px;
             
            }

            .sell_pro{
              color: #000000;
              font-family: "Segoe UI-Semibold", Helvetica;
              font-size: 36px;
              font-weight: 400;
              height: 48px;
              left: 35%;
              letter-spacing: -0.36px;
              line-height: normal;
              position: absolute;
              margin-top: 50px;
            }

            .rectblur{
              width: -webkit-fill-available;
              height: 588px;
            }
            .sell_pro2{
              color: #000000;
              font-family: "Segoe UI-Semibold", Helvetica;
              font-size: 36px;
              font-weight: 400;
              height: 48px;
              left: 35%;
              letter-spacing: -0.36px;
              line-height: normal;
              position: absolute;
              margin-top: 100px;
            }

            .final_img{
              height: 425px;
              left: 0;
              position: relative;
              width: 1355px;
            }

            .final_img1{
              height: 489px;
              left: 0;
              position: relative;
              /* top: 100px; */
              width: 1555px;
          }

          .sell_pro3{
            color: #000000;
            font-family: "Segoe UI-Semibold", Helvetica;
            font-size: 36px;
            font-weight: 400;
            height: 48px;
            letter-spacing: -0.36px;
            line-height: normal;
            position: relative;
            margin-top: 140px;
        
          }

          .short_line{
               height: 30px;
               position: relative;
               text-align: left;
               background-color: #f1f1f1;
          }
          .decor{
              text-align: right;
              position: relative;
              color: #000000;
              margin-right: 20px;
              margin-top: 20px;
          }

          .container {
            display: flex;
            justify-content: space-between;
            height: auto;
          }
          
          /* Style the filter section */
          .filter {
            padding: 20px;
            box-sizing: border-box;
            position: relative;
            height: auto;
          }
          .filter1 {
            flex: 1;
            padding: 20px;
            box-sizing: border-box;
            background-color: #f1f1f1;
            position: relative;
            height: auto;
          }
          .filter2 {
            flex: 1;
            padding: 20px;
            box-sizing: border-box;
            background-color: #f1f1f1;
            position: relative;
            height: auto;
          }
          
          .itemss {
            flex: 3;
            padding: 20px;
            box-sizing: border-box;
            margin-left: 20px;
            height: auto;
            width: 60%;
          }
          
          .items{
            padding: 20px;
            box-sizing: border-box;
            /* background-color: #f1f1f1; */
            height: auto;
          }
          .items1{
            padding: 12px;
            box-sizing: border-box;
            background-color: #f1f1f1;
            width: 110%;
            height: 50px;
            display: flex;
        
          }

          .item_pos{
          margin-left: 10px;
          font-size: 12px;
          margin-top: 4px;

          
          }

          .item_pos_show{
            margin-left: 20px;
            font-size: 12px;
            margin-top: 4px;
          }


          .categ{
            text-align: left;
          }
          .first_view{
            display: flex;
          }

          .last_view{
            display: flex;
            margin-left: 43%;
          }

          .tot_item{
            width: 98px;
          }

          .item_view{
            width: 54px;
            font-size: 10px;
          }

          .container1 {
            display: flex;
            flex-wrap: wrap;
            /* justify-content: space-between; */
            /* width:50%; */
          }
          
          /* Style the cards */
          .card {
            /* flex: 0 0 calc(33.33% - 20px); Adjust the width of each card based on your requirements */
            margin-bottom: 20px;
            padding: 20px;
            /* border: 1px solid #ccc; */
            box-sizing: border-box;
          }

          .card2 {
            /* flex: 0 0 calc(33.33% - 20px); Adjust the width of each card based on your requirements */
            margin-bottom: 20px;
            padding: 20px;
            /* border: 1px solid #ccc; */
            box-sizing: border-box;
          }

          .card2{
            flex-grow: 0;
    flex-shrink: 0;
    /* flex-basis: calc(33.33% - 20px); */
    margin-bottom: 20px;
    padding: 20px;
    /* border: 1px solid #ccc; */
    box-sizing: border-box;

          }

         
          
    

          .pro_Cr {
            text-align: left;
            font-size: 11px;
        }

        .pro_card_view{
          height: 70%;
          width: 100%;
        }

          .recent_view_pro{
              color: #000000;
              font-family: "Segoe UI-Semibold", Helvetica;
              font-size: 36px;
              font-weight: 400;
              height: 47px;
              left: 0;
              letter-spacing: -0.36px;
              line-height: normal;
              position: relative;
              text-align: left;
              /* margin-top: 100px; */
          }

          .decor_page{
            text-align: left;
            margin-top: 20px;
            margin-left: 20px;
          }

          .share_social{
            /* display: flex; */
            /* flex-wrap: wrap; */
            /* justify-content: space-between; */
          }

          .rating_detail_page{
            display: flex;
            flex-wrap: wrap;
            margin-left: 20px;
            /* justify-content: space-between; */
          }

          .rating{
            margin-top: -20px;
          }

          .social_container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .detail_page_view{
              height: 378px;
              left: 0;
              object-fit: cover;
              position: relative;
              top: 0;
              width: 372px;
              /* border: 1px solid #ccc; */
          }
          .price_amount{
            flex: 3;
            padding: 20px;
            box-sizing: border-box;
            /* margin-left: 20px; */
            height: auto;
            /* border: 1px solid #ccc; */
          }

          .price_a{
            color: #000000;
font-family: "Segoe UI-Semibold", Helvetica;
font-size: 36px;
font-weight: 400;
height: 32px;
left: 50%;
letter-spacing: -0.36px;
line-height: normal;
position: absolute;
          }

          .price_stock{
              color: #6ec654;
              font-family: "Segoe UI-Regular", Helvetica;
              font-size: 18px;
              font-weight: 400;
              height: 32px;
              letter-spacing: -0.36px;
              line-height: normal;
              position: relative;
              margin-top: 40px;
              margin-right: 75%;

          }

          .price_detail{
            text-align: left;
            margin-left: 20%;
/* margin-top: 60px;       */
    }

    .price_detail1{
      text-align: left;
      margin-left: 20%;
    }

    .cart_detail{
      flex: 3;
      box-sizing: border-box;
      height: auto;
    }
    .count_cart{
      margin-right: 40px;
      width: 10%;
      height: 35px;
      border-radius: 5px;
  }

    .add_cart{
      margin-right: 25px;
      background-color: red;
      border-radius: 2px;
      border: red;
      width: 30%;
      height: 50px;
    color: #ffffff; 
    }
 
    .categ1{
      /* margin-left: 20px; */
      text-align: center;
    }
    

    .tabs {
      display: flex;
      margin-left: 35%;
      /* margin-top:10px;   */
      
        }
    
    .tab {
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      color: #000000;
font-family: "Segoe UI-Semibold", Helvetica;
font-size: 26px;
font-weight: 400;
    }
    
    /* Style the active tab */
    .tab.active {
      border-color: #007bff;
    }
    
    /* Apply styles to the tab content */
    .tab-content {
      display: none;
      padding: 20px;
    }
    
    .tab-content.active {
      display: block;
    }

    .review_hint{
      text-align: left;
      margin-left: 5%;
    }

    .review_detail{
      text-align: left;
      margin-left: 5%;
    }

    .short_line1 {
      height: 100px;
      margin-left: 127px;
      position: relative;
      width: 85%;
      text-align: left;
      background-color: #c2f0ec;
      display: flex;
    }

    .price_amount1 {
      flex: 3 1;
      box-sizing: border-box;
      height: auto;
      margin-left: 38px;
      background-color: #f6f6f6;
      margin-top: 20px;
      width: 20%;
      /* display: flex; */
    }

    .price_amount5 {
      flex: 3 1;
      box-sizing: border-box;
      height: auto;
      margin-left: 38px;
      background-color: #f6f6f6;
      margin-top: 20px;
      display: flex;
      width: 20%;
    }

    .payment_gate{
      box-sizing: border-box;
      height: 60%;
      width: 29%;
      background-color: #f6f6f6;
      margin-top: 20px;
      margin-left: 15px;
      margin-right: 5px;
      text-align: left;
    }

    .pay_detail{
       margin-top: 10px;
       margin-left: 10px;
    }

    .price_amount2 {
      background-color: #f6f6f6;
      box-sizing: border-box;
      flex: 3 1;
      height: auto;
      margin-left: 8.5%;
      margin-top: 20px;
      width: 59%;
    }

    .delivery_name{
      /* padding: 10px; */
      margin-left: 20px;
    }
    .delivery_add{
      /* padding: 10px; */
      margin-left: 20px;
    }

    .delivery_btn1{
       color: #000000;
       background-color: #ffffff;
       width: 100px;
       height: 40px;
       border: #ffffff;
    }

    .delivery_details{
      width: 85%;
      margin-top: 10px;
      margin-left: 20px;
    }
    .delivery_btn{
      margin-top: 25px;

   }

   .product_img {
    width: 20%;
    padding: 10px;
    display: list-item;
  
  }

  .product_pri{
    margin-top: 20px;
    text-align: left;
  }

  .price_amount1 {
    /* display: flex; */
    align-items: center;
  }
  
  .product_img1 {
    margin-right: 10px; /* Adjust as needed for spacing */
  }
  
  .add_to_cart {
    margin-left: auto; /* Pushes the button to the right */
  }

  .orde_tot{
    display: flex;
  }

  .orde_pri{
    margin-left: 50%;
  }

  .orde_pri1{
    margin-left: 41%;
  }

  .orde_pri2{
    margin-left: 54%;
  }

  .orde_pri3{
    margin-left: 64%;
  }

  .save_amount{
    text-align: center;
    color: #27AE60;
    margin-left: 20px;
  }

  .coupon_app{
    height: 50px;
    color: #27AE60;
    background-color: #ffffff;
    width: 97%;
  }

  .coupon_a{
      margin-left: 20px;
      padding: 10px;
  }

  .save_for{
    text-align: left;
    padding: 10px;
  }

  .hr_tag{
    width: 183%;
  }

  .logout{
    width: 25px;
    margin-top: 35px;
    margin-left: 100%;
    cursor: pointer;
  }

  .country_name{

    margin-top: 15px;
    margin-left: 169%;
    border: none;
    color: #7b7b7b;
    font-family: "Segoe UI-Semibold", Helvetica;
    font-size: 15px;
    font-weight: 400;
  }

  .language_name{
    margin-top: 15px;
    margin-left: 89%;
    border: none;
    color: #7b7b7b;
    font-family: "Segoe UI-Semibold", Helvetica;
    font-size: 15px;
    font-weight: 400;

  }

  .twitter1{
    cursor: pointer;
    width: 23px;
    /* margin-left: 20px; */
}
.twitter2{
  cursor: pointer;
  width: 32px;
  margin-left: 20px;
}

/* .grid-view .card {
  display: inline-block;
  width: 200px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
} */

/* List View */
/* .list-view .card {
  display: block;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
} */


.in_stock {
  color: green;
}

.out_stock {
  color: red;
}

.full_profile{
  display: flex;
  margin-top: 5%;
  margin-left: 10%;
}

.profile_sidebar{
  border-radius: 5px;
  padding: 10px;
  width: 300px; /* Set the width as needed */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  background-color: #fff; /* Set background color */
  text-align: left;

}

.profile_detail{
  position: relative;
    background-color: #F4F5F5;
    height: auto;
    width: 75%;
    margin-left: 2%;
    padding: 20px;

}

.profile_name {
  display: flex;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 10px;
  width: 300px; /* Set the width as needed */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  background-color: #fff; /* Set background color */
  font-family: "Segoe UI-Semibold", Helvetica;
}

.pro_img {
  width: 70px; /* Set the image width */
  border-radius: 50%; /* Make the image round */
  margin-bottom: 10px; /* Add space between image and text */
}

/* Optional: Add hover effect */
/* .profile_name:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.profile_sidebar:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
} */

.hello_pro{
  font-size: small;
  
}

.name_p{
  margin-top: 7px;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.name_pro{
  font-size:large;
  margin-left: 20px;
  margin-top: -7px;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.my_or{
  text-align: left;
  cursor: pointer;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.selected.submenu {
  /* background-color: #f0f0f0; Set your desired background color */
  background-color: #c2f0ec;
  
}

.submenu{
  cursor: pointer;
}

.f_name{
  background-color: #c2f0ec;
  color: #000000;
  width: 40%;
  height: 50px;
  border-radius: 5px;
  border:  #c2f0ec;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.l_name{
  margin-left: 20px;
  background-color: #c2f0ec;
  color: #000000;
  width: 40%;
  height: 50px;
  border-radius: 5px;
  border:  #c2f0ec;
  font-family: "Segoe UI-Semibold", Helvetica;

}

.fir_name{
  text-align: left;
}

.lable_name{
  color: #000000;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.hide{
  display: none;
}

.gender_female{
   margin-left: 20px;
}

input[type="radio"]:checked + label {
  color: #16887d; 
}

.addaddress{
  color: #16887d;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  width: 40%;
  height: 50px;
  border:  #c2f0ec;
  background-color: #EADFED;
}

.adds,.notification_list{
  display: flex
}

.address_list{
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px
}

.add_edit{
  margin-left: 88%;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.add_type{
  background-color: #EADFED;
  border-radius: 10px;
  padding: 3px;
  width: 90px;
  text-align: center;
}

.notification{
    width: 10%;
    padding: 10px;
}

.noti_img{
  width: 65px;
}

.n_text{
    
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 14px;
  font-weight: 400;
  color: #2FCD21;
  margin-top: 10px;
}

.n_date{
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 12px;
  font-weight: 400;
  color: #C7C7C7;
  margin-top: -10px;
}



/* popup style */


.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 1000; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(2px);
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 600px;

}

.popup-inner {
  max-width: 600px;
  margin: 0 auto;
}

.popup-overlay {
  position: relative;
  top: 0;
  left: 0;
  /* width: 100%; */
  /* height: 100%; */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}


.lable_name1{
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 14px;
  font-weight: 400;
  color: #16887d;
  margin-top: 10px;
  padding: 10px;
  text-align: left;
}

.add_loc_type{
  background-color: #EADFED;
  color: #000000;
  width: 90%;
  height: 40px;
  border-radius: 5px;
  border:  #c2f0ec;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px; /* Adjust the gap between items as needed */
}

.grid-item {
  width: 115%;
}

.add_save{
  text-align: center;
  margin-left: 66%;
  width: 17%;
  background-color: #16887d;
  color: #ffffff;
  border-radius: 5px;
  height: 40px;
}
.can_save{
  text-align: center;
 
  width: 17%;
  background-color: #16887d;
  color: #ffffff;
  border-radius: 5px;
  height: 40px;
}

.add_edit1{
  margin-left: 102%;
  width: 20px;
  height: 20px;
  margin-top: -60px;
  cursor: pointer;
}

.heart:active {
  background-color: #16887d; /* Change to whatever color you want */
}

.vector:active {
  background-color: #16887d; /* Change to whatever color you want */
}

.vector1:active {
  background-color: #16887d; /* Change to whatever color you want */
}

.logout:active {
  background-color: #16887d;/* Change to whatever color you want */
}

.add_edit1{
    margin-left: 98%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-top: 0px;
}

.myorders{
  display:flex;
  margin-top: 5%;
  margin-left: 10%;
}

.fil_order{
    /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 10px;
  width: 300px; /* Set the width as needed */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  background-color: #fff; /* Set background color */
  font-family: "Segoe UI-Semibold", Helvetica;
}
.or_status{
  text-align: left;
  width: 100%;
  margin-top: 10px;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.my_ord{
  /* margin-left: 20px; */
  position: relative;
    /* background-color: #F4F5F5; */
    height: auto;
    width: 70%;
    margin-left: 2%;
    font-family: "Segoe UI-Semibold", Helvetica;
}

.myorders{
  background-color: #F4F5F5;
  margin-top: 20px;
}
.search_bar{
  text-align: left;
}
.search_input{
  width: 75%;
  height: 53px;
  border-radius: 3px;
  /* border: #000 */
}

.in_search{
    width: 20%;
    height: 53px;
    margin-left: -2px;
    border-radius: 3px;
    color: #ffffff;
    font-family: "Segoe UI-Semibold", Helvetica;
    background-color: #D1D1D1;
    /* border: #D1D1D1; */
}

.order_de{
  position: relative;
  background-color: #F4F5F5;
  height: auto;
  width: 95%;
  font-family: "Segoe UI-Semibold", Helvetica;
  margin-top: 10px;
  border-radius: 3px;
}



.my_or1{
  text-align: left;
  cursor: pointer;
  font-family: "Segoe UI-Semibold", Helvetica;
  padding: 20px;
}

.order_details{
  display: flex;
}

.order_img{
  width: 30%;
}

.or_img{
  width: 60%;
  padding: 5px;
}
.order_name{
  margin-left: 30px;
}
.o_na{
  font-family: "Segoe UI-Semibold", Helvetica;
  color: #4F2564;
  font-size: 15px;
  text-align: left;
  /* margin-top: 10px; */
}
.o_sub{
  font-family: "Segoe UI-Semibold", Helvetica;
  color: #000000;
  font-size: 10px;
  margin-top: -10px;
  text-align: left;
}

.order_pz{
  margin-left: 10%;
  color: #4F2564;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.order_pz1{
 
  color: #4F2564;
  font-family: "Segoe UI-Semibold", Helvetica;
}

.order_st{
  margin-left: 10%;
}

.order_st1{
  color: #52B849;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 15px;
  text-align: left;
}

.order_st2{
  color: #4F69F5;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 10px;
  text-align: left;
  margin-top: -10px;
  cursor: pointer;
}

.trackorder1{
  position: relative;
  background-color: #F4F5F5;
  height: auto;
  width: 95%;
  font-family: "Segoe UI-Semibold", Helvetica;
  margin-top: 3%;
  border-radius: 10px;
  margin-left: 40px;
}

.track_flex{
  display: flex;
  padding: 20px;
}

.track_detail{
  text-align: left;
}

.track_no{
  /* margin-left: 57%; */
  text-align: right;
}

.order-status {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.status {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  margin-left: 13px;

}

.status.active {
  background-color: #16887d;
}

.status-line {
  border: none;
  border-top: 2px solid #16887d;
  flex: 1;
  /* margin: 0 10px; Adjust the margin as needed */
}
.backtoorders{
  text-align: right;


}

.order_button{
  border-radius: 5px;
  margin-right: 20px;
  width: 15%;
  height: 45px;
  border: none;
  background-color: #CACACA;
}

.grid-container1{
  text-align: left;
  margin-left: 20px;
}
.add_list{
  margin-top: -10px;
  margin-left: 30px;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-size: 12px;
  font-weight: 400;
  color: #BAA4C0;
}

.delivery_name1{
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 16px;
  margin-left: 20px;
}

.loader-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blur {
  filter: blur(5px); /* Adjust the blur intensity as needed */
}

.order_place{
  font-family: "Segoe UI-Semibold", Helvetica;
    font-weight: 400;
    font-size: 16px;
    background-color: #f6f6f6;
    box-sizing: border-box;
    height: auto;
    margin-top: 5%;
    margin-left: 5%;
    width: 90%;
}

.pay_suc{
  font-family: "Segoe UI-Bold";
  font-weight: 400;
  font-size: 40px;
  color: #45D04B;
  text-align: center;
  margin-top: -40px;
  
}
.pay_su{
  color: #16887d;
  text-align: center;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 32px;
  margin-top: -30px;
}
.pay_su1{
  color: #626262;
  text-align: center;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 14px;
  margin-top: -20px;
}
.pay_su2{
  color: #16887d;
  text-align: center;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 20px;
  margin-top: -10px;
  cursor: pointer;
}

.track_detail_order{
  display: flex;
  width: 58%;
}

.track_img{
  width: 45%;
  height: 40%;
  border: red;
}

.pro_track{
  text-align: left;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 20px;
  color: #16887d;
  margin-top: 5px;
}
.pro_track1{
  text-align: left;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 15px;
  color: #EADFED;
  margin-top: -5px;
}
.pro_track2{
  text-align: left;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 20px;
  color: #16887d;
  margin-top: -10px;
}

.or_img_tr{

  width: 35%;
  padding: 5px;
  margin-right: 45%;
}

.pl_de{
  color: #626262;
  font-family: "Segoe UI-Semibold", Helvetica;
  font-weight: 400;
  font-size: 15px;
}

.upload_pres{
  color: red
}

.prescription{
 
  border: 1px solid #ccc;
  width: 50%;
  height: 30px;
  border-radius: 5px;
}

.pres_up{
  text-align: left;
  margin-left: 20px;
} 

.upload_btn{
  margin-left: 20px;
}

.btn-patner{
  background-color: #16887d;
    color: #ffffff;
    width: 20%;
    height: 100px;
    border-radius: 50%;
    font-size: xx-large;
}

.close{
  cursor: pointer;
  margin-left: 100%;
}

.patner_in{
  
  height: 30px;
  width: 45%;
  border-radius: 5px;

}

.patner_lab{

    margin-right: 15px;
}

.form_foot{
  background-color: #eadfed;
  border: #c2f0ec;
  border-radius: 5px;
  color: #000;
  height: 40px;
  width: 20%;
}

.price_amount {
  display: flex;
  flex-direction: column;
}

.original-price {
  text-decoration: line-through;
  color: red;
  margin-left: 12%;
}

.discounted-price {
  color: green;
  font-weight: bold;
  
}
.offer_percentage {
  color: orange;
  font-size: 22px;
  font-weight: 700;
  margin-right: 65%;
}

.price_stock.in_stock {
  color: green;
}

.price_stock.out_stock {
  color: red;
}

.payment_gate {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.pay_detail {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.coupon_app {
  margin-bottom: 10px;
}

.coupon_a {
  color: green;
  font-weight: bold;
}

.orde_tot {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 11px;
}

.orde_pri, .orde_pri1, .orde_pri2, .orde_pri3 {
  font-weight: bold;
}

.save_amount {
  color: red;
  font-weight: bold;
}

.coupon_selection {
  margin-top: 20px;
}

.coupon_selection p {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.coupon_selection div {
  margin: 10px 0;
}

.coupon_selection input[type="radio"] {
  margin-right: 10px;
}

.coupon_selection label {
  font-size: 1em;
  cursor: pointer;
}

.coupon_selection input[type="radio"]:checked + label {
  color: blue;
  font-weight: bold;
}

.coupon_selection div:hover label {
  color: #007bff;
}




.coupon_selection {
  margin-top: 20px;
}

.coupon_selection p {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.coupon_selection div {
  margin: 10px 0;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.coupon_selection div:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.coupon_selection input[type="radio"] {
  margin-right: 10px;
}

.coupon_selection label {
  font-size: 1em;
  cursor: pointer;
}

.coupon_selection input[type="radio"]:checked + label {
  color: blue;
  font-weight: bold;
}

.coupon_selection div:hover label {
  color: #007bff;
}

.product-price {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between original and final price */
  margin-right: 81%;
}

.original-price {
  text-decoration: line-through;
  color: #888; /* Gray color for the strikethrough price */
}

.final-price {
  color: #e60023; /* Red color for the final price */
  font-weight: bold;
  margin-left: 12%;
}

.order_pz1 {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between original and final price */
}

.original-price {
  text-decoration: line-through;
  color: #888; /* Gray color for the strikethrough price */
}

.final-price {
  color: #e60023; /* Red color for the final price */
  font-weight: bold;
  margin-left: 12%;
}

.close-btn{
    margin-left: 96%;
    background-color: #fff;
    border: antiquewhite;
    width: 30px;
}

.autp_c {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  /* border-radius: 4px; */
  width:200%;
}





/* media query */

/* Extra small devices (phones, 320px and up) */
@media screen and (min-width:320px) and (max-width:480px) {
  .group_img{
    width: 50%;
  }

  .header1 {
    width: 100%;
}
 
  .text-wrapper {
    color: #16887d;
    cursor: pointer;
    font-family: Segoe UI-Regular, Helvetica;
    font-size: 12px;
    position: relative;
    text-align: right;
    top: 15px;
}

.login_now {
  font-family: Segoe UI-Bold, Helvetica;
  font-size: 20px;
  font-weight: 700;
}

.google, .input-BG, .login_email {
  height: 47px;
  position: relative;
  width: 276px;
}

.input-BG {
  background-color: #c2f0ec;
  border: #c2f0ec;
  border-radius: 5px;
  height: 47px;
  position: relative;
  width: 276px;
}

.check_one, .input-BG1 {
  height: 47px;
  position: relative;
  width: 276px;
}

.continue {
  background-color: #16887d;
  border-radius: 5px;
  color: #fff;
  width: 276px;
}
.email_here {
  border-radius: 3px;
  position: relative;
  text-align: left;
  width: 58%;
}

.subscribe{
 
    width: 65px;
    font-size: 8px;

}

.btn-patner {
  background-color: #16887d;
  border-radius: 50%;
  color: #fff;
  font-size: xx-large;
  height: 100px;
  width: 98%;
}

.payment_img {
  object-fit: cover;
  position: relative;
  width: 93%;
}
.row_head{
  width: 103%;
}

.log_fun {
  margin-left: 1px;
  width: 100%;
}

.welcome-to-market {
  color: #7b7b7b;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.09px;
  line-height: normal;
  position: absolute;
  top: 15px;
}


.col-md-4, .col-md-2, .col-md-9, .col-md-3 {
  flex: 1 1 100%;
}

.header {
  padding: 5px;
  width: 35%;
  position: relative;
}

.store_loc, .trackorder, .welcome-to-market {
  text-align: right;
  font-size: 10px;
  /* margin-right: -65px !important; */
  /* margin-left: 28px; */
  width: 153px;
}

.trackorder {
  cursor: pointer;
  left: 130%;
}

.country_name {
  margin-left: 2%;
  margin-top: 25px;
}

.language_name {
  margin-left: 81%;
    margin-top: 7px;
    display: none;
}

.heart, .vector {
  cursor: pointer;
  position: absolute;
  top: 34px;
  /* left: 181px; */
}
.heart {
  left: 150.4%;
}
.vector {
  left: 180.4%;
}
.vector1 {
  cursor: pointer;
  left: 214.4%;
  position: absolute;
  top: 33px;
}

.logout {
  cursor: pointer;
  margin-left: 264%;
  margin-top: -157px;
  width: 25px;
}

.autp_c, .suggestions {
  border: 1px solid #ccc;
  width: 1000%;
}


.product-image {
  height: 150px;
  margin-left: -400px;
  margin-right: 10px;
  width: 170px;
}

.order-card, .product-info {
  align-items: center;
  display: block;
}

hr {
  border: 0;
  border-top: 1px solid;
  border-top: var(--bs-border-width) solid;
  color: inherit;
  margin: 1rem 0;
  opacity: .25;
  width: 39%;
}

.product-name{
  font-size: 9px;
    text-align: justify;
}

.quantity_details {
  display: block;
  justify-content: space-between;
  margin-left: 1px;
  margin-top: 10px;
}

.quantity-controls {
  align-items: center;
  display: block;
}

.product_remove, .product_save {
  background-color: #fff;
  border: #fff;
  color: #000;
  margin-left: -2px;
  margin-top: 10px;
}

.price_amount1, .price_amount5 {
  /* background-color: #f6f6f6; */
  box-sizing: border-box;
  flex: 3 1;
  height: 26%;
  margin-left: 38px;
  margin-top: 20px;
  width: 23%;
  position: relative;
}

.mar_place {
  color: #000;
  font-size: 27px;
  height: 48px;
  letter-spacing: -.36px;
}

.bus_to{
  color: #7b7b7b;
  font-family: "Segoe UI-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 58px;
  left: 0;
  letter-spacing: -0.11px;
  line-height: normal;
  position: relative;
  margin-left: 1px;
  margin-right: 1px;
  /* top: 60px; */
}

.slide_off{
  height: 151px;
  left: 0;
  position: relative;
  top: 0;
  width: 61%;
  margin-right: 465px;
  display: none;
}

.slide_off1 {
  height: 151px;
  left: 64%;
  position: absolute;
  width: 35%;
  margin-top: -152px;
  display: none;
}

.sell_best_product {
  background-color: #f4f5f5;
  height: auto;
  position: relative;
  width: 100%;
  /* display: inline-table; */
}

.final_img {
  height: auto;
  width: 100%;
}

.short_line {
  background-color: #f1f1f1;
  height: 54px;
  position: relative;
  text-align: left;
}

.detail_page_view {
  height: 130px;
  left: 0;
  object-fit: cover;
  position: relative;
  top: 0;
  width: 70px;
}

.price_a {
  color: #000;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 20px;
  left: 32%;
  position: absolute;
}

.original-price {
  color: red;
  margin-left: 23%;
}

.price_stock {
  color: #6ec654;
  font-family: Segoe UI-Regular, Helvetica;
  font-size: 18px;
  margin-right: 75%;
  margin-top: 40px;
  position: relative;
  width: 78%;
}

.offer_percentage {
  color: orange;
  font-size: 18px;
  font-weight: 700;
  margin-right: 52%;
  width: 95%;
}

.price_detail, .price_detail1 {
  margin-left: 0%;
  text-align: left;
}

.tab{
  font-size: 20px;

}

.tabs-container{
  width: 6%;
}

.short_line1 {
  background-color: #c2f0ec;
  display: flex;
  height: 156px;
  margin-left: 7px;
  position: relative;
  text-align: left;
  width: 96%;
}

.delivery_btn {
  margin-top: 25px;
  width: 41%;
}

.delivery_btn1 {
  background-color: #fff;
  border: #fff;
  color: #000;
  height: 40px;
  width: 79px;
  /* margin-left: -54px; */
}

.container {
  display: block;
  height: auto;
  justify-content: space-between;
}

.place_del {
  background-color: #16887d;
  border: #16887d;
  color: #fff;
  margin-left: -10%;
  padding: 10px;
}

.payment_gate {
  background-color: #f6f6f6;
  box-sizing: border-box;
  height: 60%;
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 20px;
  text-align: left;
  width: 95%;
}

.price_amount2 {
  background-color: #f6f6f6;
  box-sizing: border-box;
  flex: 3 1;
  height: auto;
  margin-left: 8.5%;
  margin-top: 20px;
  width: 89%;
}

.price_amount1, .price_amount5 {
  background-color: #fff;
  box-sizing: border-box;
  flex: 3 1;
  height: 9%;
  margin-left: 0px;
  margin-top: 20px;
  position: relative;
  width: 176%;
}

.full_profile {
  display: block;
  margin-left: 10%;
  margin-top: 5%;
}

.profile_detail {
  background-color: #f4f5f5;
  height: auto;
  margin-left: 1%;
  padding: 20px;
  position: relative;
  width: 92%;
  margin-top: 10px;
}

.addaddress {
  background-color: #eadfed;
  border: #c2f0ec;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 50px;
  letter-spacing: 0;
  position: relative;
  width: 100%;
}

.add_save, .can_save {
  background-color: #16887d;
  border-radius: 5px;
  color: #fff;
  height: 40px;
  text-align: center;
  width: 17%;
  font-size: 12px;
}

.fil_order {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px #0000001a;
  padding: 10px;
  text-align: center;
  width: 279px;
  margin-top: 10px;
}
.my_ord {
  font-family: Segoe UI-Semibold, Helvetica;
  height: auto;
  margin-left: 0%;
  position: relative;
  width: 97%;
  margin-top: 20px;
}

.container1 {
  display: block;
  /* flex-wrap: wrap; */
  width: 235%;
  margin-top: 70px;
  margin-left: -31px;
}

.items1 {
  background-color: #f1f1f1;
  /* display: flex; */
  height: 50px;
  padding: 12px;
  width: 110%;
  display: block;
}

.item_pos, .item_pos_show {
  font-size: 12px;
  margin-top: 29px;
  margin-left: -11px;
}

.item_pos_show {
  margin-left: -34px;
  margin-top: -33px;
}

.last_view {
  margin-left: 57%;
  display: none;
}

.first_view {
  display: block;
}

.myorders {
  display: block;
  margin-left: 10%;
  margin-top: 20px;
}

.order_details {
  display: block;
}
.track_flex {
  display: block;
  padding: 20px;
}

.track_detail_order {
  display: block;
  width: 58%;
}
.track_no {
  text-align: left;
}

.or_img_tr {
  margin-right: 45%;
  padding: 5px;
  width: 182%;
}

.trackorder1 {
  background-color: #f4f5f5;
  border-radius: 10px;
  font-family: Segoe UI-Semibold, Helvetica;
  height: auto;
  margin-left: 10px;
  margin-top: 3%;
  position: relative;
  width: 95%;
}
.order-status {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-left: -97px;
}
.order_button {
  background-color: #cacaca;
  border: none;
  border-radius: 5px;
  height: 45px;
  margin-right: 20px;
  width: 50%;
}
.backtoorders {
  text-align: center;
}

.delivery_details {
  margin-left: -16px;
  margin-top: 33px;
  width: 80%;
  text-align: justify;
}

.count_cart {
  border-radius: 5px;
  height: 35px;
  margin-right: 40px;
  width: 22%;
}

.add_cart {
  background-color: red;
  border: red;
  border-radius: 2px;
  color: #fff;
  height: 40px;
  margin-right: 21px;
  width: 34%;
  font-size: 12px;
}
.delivery_add, .delivery_name {
  margin-left: 20px;
  font-size: 14px;
}
}
@media screen and (min-width:576px) and (max-width:760px) {

  .log_fun {
    width: 100%;
    margin-left: 3px;
  }
  .btn-patner {
    background-color: #16887d;
    border-radius: 50%;
    color: #fff;
    font-size: xx-large;
    height: 100px;
    width: 88%;
  }

  .payment_img {
    object-fit: cover;
    position: relative;
    width: 96%;
}
.group_img {
  width: 45%;
  margin-right: 53%;
}




.col-md-4, .col-md-2, .col-md-9, .col-md-3 {
  flex: 1 1 100%;
}

.header {
  padding: 5px;
  position: relative;
  width: 35%;
}

.store_loc, .trackorder, .welcome-to-market {
  text-align: right;
  font-size: 10px;
  /* margin-right: -65px !important; */
  /* margin-left: 28px; */
  width: 153px;
}

.trackorder {
  cursor: pointer;
  left: 130%;
}

.country_name {
  margin-left: 222%;
  margin-top: 6px;
}

.language_name {
  margin-left: 81%;
    margin-top: 7px;
    display: none;
}

.pro_logo1 {
  cursor: pointer;
  margin-right: 80%;
  margin-top: 18%;
  position: relative;
}
.heart, .vector {
  cursor: pointer;
  position: absolute;
  top: 34px;
  /* left: 181px; */
}
.heart {
  left: 150.4%;
}
.vector {
  left: 180.4%;
}
.vector1 {
  cursor: pointer;
  left: 214.4%;
  position: absolute;
  top: 33px;
}

.logout {
  cursor: pointer;
        margin-left: 252%;
        margin-top: -150px;
        width: 25px;
}

.autp_c, .suggestions {
  border: 1px solid #ccc;
  width: 1000%;
}
 
}

/* Media queries for responsive design */
@media screen and (min-width:768px) and (max-width:990px) {

 
  .row_head{
    width: 102%;
  }

  .group_img{
    width: 100%;
  }
  .btn-patner {
    background-color: #16887d;
    border-radius: 50%;
    color: #fff;
    font-size: xx-large;
    height: 100px;
    width: 35%;
}

.payment_img {
  object-fit: cover;
  position: relative;
  width: 150%;
}

.input-BG {
  background-color: #c2f0ec;
  border: #c2f0ec;
  border-radius: 5px;
  height: 47px;
  position: relative;
  width: 300px;
}

.check_one, .input-BG1 {
  height: 47px;
  position: relative;
  width: 300px;
}

.continue {
  background-color: #16887d;
  border-radius: 5px;
  color: #fff;
  width: 300px;
}

.subscribe{
 
    width: 65px;
    font-size: 8px;

}
.google, .login_email {
  height: 47px;
  position: relative;
  width: 300px;
}
.email_here {
  border-radius: 3px;
  position: relative;
  text-align: left;
  width: 60%;
}

.header {
  padding: 5px;
  position: relative;
  width: 72%;
}

.country_name {
  margin-left: 326%;
}

.trackorder {
  cursor: pointer;
  left: 99%;
}

.language_name {
  margin-left: 278%;
}
.heart {
  left: 104.4%;
}
.vector {
  left: 111.4%;
}

.vector1 {
  cursor: pointer;
  left: 119.4%;
  position: absolute;
  top: 70px;
}

.logout {
  cursor: pointer;
  margin-left: 227%;
  margin-top: 30px;
  width: 25px;
}
.sell_best_product {
  background-color: #f4f5f5;
  height: auto;
  position: relative;
  width: 99%;
}

.sell_best_product {
  background-color: #f4f5f5;
  height: auto;
  position: relative;
  width: 99%;
}
}


@media screen and (min-width:992px) and (max-width:1020px)  {
  .row_head {
    background-color: #f0f8ff;
    width: 110%;
}

.payment_img {
  object-fit: cover;
  position: relative;
  width: 200%;
}

.btn-patner {
  background-color: #16887d;
  border-radius: 50%;
  color: #fff;
  font-size: xx-large;
  height: 100px;
  width: 30%;
}

.header {
  padding: 5px;
  position: relative;
  width: 73%;
}

.store_loc {
  left: 70%;
}

.trackorder {
  cursor: pointer;
  left: 91%;
}

.country_name, .language_name {
  border: none;
  color: #7b7b7b;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 15px;
  font-weight: 400;
  margin-top: 9px;
}

.country_name {
  margin-left: 280%;
}

.logout {
  cursor: pointer;
  margin-left: 227%;
  margin-top: 36px;
  width: 25px;
}
.language_name {
  margin-left: 248%;
}

.heart {
  left: 104.4%;
}

.vector {
  left: 111.4%;
}

.vector1 {
  cursor: pointer;
  left: 120.4%;
  position: absolute;
  top: 70px;
}

.email_here {
  border-radius: 3px;
  position: relative;
  text-align: left;
  width: 51%;
}
}


@media screen and (min-width:1024px) and (max-width:1199px)  {
  .row_head {
    background-color: #f0f8ff;
    width: 97%;
}

.payment_img {
  object-fit: cover;
  position: relative;
  width: 150%;
}

.email_here {
  border-radius: 3px;
  position: relative;
  text-align: left;
  width: 50%;
}

.store_loc {
  left: 72%;
}

.trackorder {
  cursor: pointer;
  left: 87%;
}

.country_name {
  margin-left: 181%;
}

.language_name {
  margin-left: 115%;
}

.header {
  padding: 5px;
  position: relative;
  width: 91%;
}

.country_name, .language_name {
  border: none;
  color: #7b7b7b;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 15px;
  font-weight: 400;
  margin-top: 11px;
}

.heart {
  left: 88.4%;
}
.vector {
  left: 93.4%;
}

.vector1 {
  cursor: pointer;
  left: 99.4%;
  position: absolute;
  top: 70px;
}

.logout {
  cursor: pointer;
  margin-left: 120%;
  margin-top: 35px;
  width: 25px;
}
} 

.pass_show{
  margin-left: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px;
}
@media screen and (min-width:1200px) and (max-width:1250px)  {

.email_here {
  border-radius: 3px;
  position: relative;
  text-align: left;
  width: 43%;
}

.payment_img {
  object-fit: cover;
  position: relative;
  width: 100%;
}

.menu {
  height: 88px;
  margin-top: 30px;
  width: 99%;
}

.banner-slideshow {
  height: 500px;
  overflow: hidden;
  position: relative;
  width: 99%;
}

.banner-slideshow {
  height: 500px;
  overflow: hidden;
  position: relative;
  width: 99%;
}
.sell_best_product {
  background-color: #f4f5f5;
  height: auto;
  position: relative;
  width: 99%;
}

.final_img {
  height: 425px;
  width: 1260px;
}

.store_loc {
  left: 77%;
}
}

@media screen and (min-width:1251px) and (max-width:1300px)  {

  .email_here {
    border-radius: 3px;
    position: relative;
    text-align: left;
    width: 43%;
  }
  
  .payment_img {
    object-fit: cover;
    position: relative;
    width: 100%;
  }
  
  .menu {
    height: 88px;
    margin-top: 30px;
    width: 100%;
  }
  
  .banner-slideshow {
    height: 500px;
    overflow: hidden;
    position: relative;
    width: 99%;
  }
  
  .banner-slideshow {
    height: 500px;
    overflow: hidden;
    position: relative;
    width: 99%;
  }
  .sell_best_product {
    background-color: #f4f5f5;
    height: auto;
    position: relative;
    width: 99%;
  }
  
  .final_img {
    height: 425px;
    width: 1260px;
  }
  
  .store_loc {
    left: 77%;
  }

  .header {
    padding: 5px;
    position: relative;
    width: 93%;
}

.short_line {
  background-color: #f1f1f1;
  height: 30px;
  position: relative;
  text-align: left;
  width: 100%;
}

.decor {
  color: #000;
  margin-right: 162px;
  margin-top: 20px;
  position: relative;
  text-align: right;
}

.item_view {
  width: 56px;
  margin-right: 10px;
}

.pay_detail {
  background-color: #fff;
  border-radius: 10px;
  padding: 0px;
}
.pay_detail {
  margin-left: 0px;
  margin-top: 10px;
}

.price_a {
  color: #000;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 36px;
  left: 55%;
  position: absolute; 
}

.price_a {
  color: #000;
  font-family: Segoe UI-Semibold, Helvetica;
  font-size: 36px;
  left: 55%;
  position: absolute; 
}

.about-us-team-member, .support-center {
  color: #818181;
  font-family: Segoe UI-Regular, Helvetica;
  font-size: 16px;
  letter-spacing: -.2px;
  line-height: 40px;
  position: relative;
  text-align: left;
}

.sell-on-market-place {
  color: #818181;
  font-family: Segoe UI-Regular, Helvetica;
  font-size: 16px;
  letter-spacing: -.2px;
  line-height: 40px;
  position: relative;
  text-align: left;
}
  }

  .image-gallery {
    display: flex;
  }
  
  .main-image {
    flex: 3;
    text-align: center;
  }
  
  .main-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: contain;
  }
  
  .image-thumbnails {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-left: 20px;
  }
  
  .image-thumbnails img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .image-thumbnails img.active {
    border: 2px solid #007bff;
  }
  